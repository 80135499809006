import { Pagination } from "@mui/material";
import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { NewsItem } from "../../types/searchData.types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getApi, remove } from "../../services/apiService";
import { formatDate, getCurrentDate } from "../../utils/dateFunctions";
import {
  advanceSearch,
  commentPost,
  commonStrings,
  homeSection,
  searchNames,
} from "../../services/stringConstant";
import { apiConstant } from "../../services/apiConfig";
import { BookMark, SubBookMark } from "../../types/bookmark.types";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../contexts/LoadingContent";
import { User } from "../../types/user.types";
import ShimmerEffect from "../ShimmerEffect";

interface SearchLandingProps {
  searchData?: NewsItem[];
  setSearchData?: (
    data?: NewsItem[] | null | undefined | [] | unknown | any
  ) => void;
  isSearch: boolean;
  searchField?: string;
  searchFieldData?: any;
  searchName?: string;
  refindSearch?: (isSearch: boolean) => void;
  setIsRefineFalse?: () => void;
}

interface Errors {
  fromDate?: string;
  toDate?: string;
}

const SearchData: React.FC<SearchLandingProps> = ({
  searchName,
  searchData,
  setSearchData,
  isSearch,
  // searchField = 'detail', for comment option by ram
  searchField = "",
  searchFieldData,
  refindSearch,
  setIsRefineFalse,
}) => {
  const [category, setCategory] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const [apiData, setApiData] = useState<NewsItem[]>([]);
  const [caseLawdata, setCaseLawdata] = useState<NewsItem[]>([]);
  const [pageOriData, setPageOriData] = useState<NewsItem[]>([]);
  const intialCount = 10;
  const [prevCount, setPrevCount] = useState<number>(1);
  const [nextCount, setNextCount] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [refineData, setRefineData] = useState<string>("");
  const [refineItem, setRefineItem] = useState<NewsItem[]>([]);
  const [spinner, setSpinner] = useState<boolean>(true);
  const [bookMarks, setBookMarks] = useState<BookMark[]>([]);
  const [bookMarkPage, setBookMarkPage] = useState<number>(1);
  const [bookMarksPerPage] = useState<number>(10);
  const [selectedBookMark, setSelectedBookMark] = useState<BookMark>();
  const [years, setYears] = useState<number[]>([]);
  const [states, setStates] = useState<{ state: string }[]>([]);
  const [state, setState] = useState<string>("");
  const [uts, setUts] = useState<{ ut: string }[]>([]);
  const [ut, setUt] = useState<string>("");
  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});
  const [fromDate, setFromDate] = useState<string>(
    formatDate(new Date(new Date().getFullYear(), 0, 1))
  );
  const [toDate, setToDate] = useState<string>(formatDate(new Date()));
  const [errors, setErrors] = useState<Errors>({});
  const [isRefineMode, setIsRefineMode] = useState(false);
  const [todaysCase, setTodaysCase] = useState(false);

  // Add a separate local loading state
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setLocalLoading(true);
    setYears(generateYears);
    // Reset filters and set date to current year when location changes
    const currentYear = new Date().getFullYear();
    setFromDate(formatDate(new Date(currentYear, 0, 1)));
    setToDate(formatDate(new Date()));
    setRefineData("");
    setRefineItem([]);

    // Set current year in dropdown
    const yearSelect = document.querySelector("select") as HTMLSelectElement;
    if (yearSelect) {
      yearSelect.value = currentYear.toString();
    }

    const fetchData = async () => {
      if (searchData && searchData.length > 0) {
        setApiData(searchData);
      } else if (!isSearch) {
        if (
          !location.state &&
          location.state === null &&
          location.pathname !== homeSection.userMenuSettings[1].value
        ) {
          navigate("/");
        } else if (
          location.pathname === homeSection.userMenuSettings[1].value
        ) {
          await getBookMarks();
        } else if (
          !location.pathname.includes("/gst/sgst") &&
          location.state != null
        ) {
          const { apiUrl, catName, todaysCase } = location.state;
          setCategory(catName);
          try {
            const res = await getApi(apiUrl);
            setApiData(res);
            if (todaysCase) {
              setTodaysCase(true);
            }
          } catch (e) {
            console.error("Error fetching data:", e);
          } finally {
            setLoading(false);
            setLocalLoading(false);
          }
        }
      }

      if (location.pathname.includes("/gst/sgst")) {
        const { apiUrl, catName } = location.state;
        setCategory(catName);
        try {
          const statesRes = await getApi(apiConstant.getSGSTState);
          setStates(statesRes);
          setState(statesRes[0]?.state);

          const utsRes = await getApi(apiConstant.getSGSTUT);
          setUts(utsRes);
        } catch (e) {
          console.error("Error fetching SGST data:", e);
        }
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      setLoading(false);
      setLocalLoading(false);
    };

    fetchData();
  }, [searchData, location.state, location]);

  useEffect(() => {
    const fetchSgstData = async () => {
      if (location.state != null) {
        const { apiUrl } = location.state;
        if (location.pathname.includes("/gst/sgst")) {
          if (ut !== "" || state !== "") {
            setLoading(true);
            setLocalLoading(true);
            let url = apiUrl;
            url = url.replace("@state", state !== "" ? state : null);
            url = url.replace("@UT", ut !== "" ? ut : null);

            try {
              const res = await getApi(encodeURI(url));
              setApiData(res);
            } catch (e) {
              console.error("Error fetching SGST data:", e);
            } finally {
              setLoading(false);
              setLocalLoading(false);
            }
          } else {
            setLoading(false);
            setLocalLoading(false);
          }
        }
      }
    };

    fetchSgstData();
  }, [state, ut]);

  useEffect(() => {
    if (apiData.length > 0 || refineItem.length > 0) {
      const dataToUse = refineItem.length > 0 ? refineItem : apiData;

      setPageOriData(dataToUse);
      setCaseLawdata(dataToUse.slice(0, intialCount));

      setPage(1);
      setPrevCount(1);
      setNextCount(10);

      if (dataToUse.length === 0) setSpinner(false);
    } else {
      setPageOriData([]);
      setCaseLawdata([]);
      setPage(1);
      setPrevCount(1);
      setNextCount(10);
    }
  }, [apiData, refineItem]);

  const pageChange = (event: any, value: number) => {
    setPage(value);
    const targetCount = value;
    const Prev = (targetCount - 1) * intialCount + 1;
    setPrevCount(Prev);
    const Next = targetCount * intialCount;
    setNextCount(Next);
    const localArray = pageOriData.slice(Prev - 1, Next);
    setCaseLawdata(localArray);
  };

  const rowDataClickandler = (item: NewsItem) => {
    if (item.caselawUrl) {
      let getval = item.caselawUrl.split("/");
      let keyword = getval[getval.length - 1] || "";
      let webpathArr = item.webpath.split("/");
      let id = webpathArr[webpathArr.length - 1];
      const detailPageWindow: Window | null = window.open();
      if (detailPageWindow)
        detailPageWindow.location.href = `${window.location.protocol}//${
          window.location.host
        }/searchcaselaw/details?id=${id}&type=caselaw${
          keyword !== "" ? `&keyword=${keyword}` : ""
        }`;
    } else if (item.url?.includes("GetPdfBlobById")) {
      let newUrl = item.webpath.split("/");
      let urlId = newUrl[newUrl.length - 1];
      window.open(`/news/details?id=${urlId}&type=pdf`, "_blank");
    } else if (item.pressRelease_url) {
      if (item.description) {
        let newUrl = item.webpath.split("/");
        let urlId = newUrl[newUrl.length - 1];
        window.open(
          `/pressrelease/details?id=${urlId}&type=pressrelease`,
          "_blank"
        );
      } else {
        return;
      }
    } else if (item.webpath) {
      let newUrl = item.webpath.split("/");
      let urlId = newUrl[newUrl.length - 1];
      let keyword = searchFieldData?.keyword || "";
      const type = selectedBookMark?.types.toLowerCase();
      let webpath = item.webpath.split("/");
      let path = webpath[0] || "news";

      window.open(
        `/${path}/details?id=${urlId}&view=${
          item.notification_Number ? searchNames.Notification : searchField
        }${
          category.includes(commonStrings.TODAYSCASE) ||
          item.webpath.includes("caselaws")
            ? `&type=caselaw`
            : item.webpath.includes("notification")
            ? `&type=notification`
            : ""
        }${
          item.webpath.includes("notification")
            ? `${keyword !== "" ? "&keyword=" + keyword : ""}`
            : ""
        }`,
        "_blank"
      );
    } else if (item.url?.includes(".PDF")) {
      window.open(item.url, "_blank");
    }
  };
  const toggleReadMore = (index: number) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const validate = (): boolean => {
    const newErrors: Errors = {};
    if (!fromDate) {
      newErrors.fromDate = "From Date is required";
    }
    if (!toDate) {
      newErrors.toDate = "To Date is required";
    }
    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      newErrors.toDate = "To Date should be after From Date";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate() === true) {
      pageChange(null, 0);
      const { apiFilterUrl } = location.state;
      let url = apiFilterUrl;
      if (!url) return;
      url = url.replace("@From_Date", fromDate);
      url = url.replace("@To_Date", toDate);
      getApi(url)
        .then((res) => {
          setApiData(res);
          setPage(1);
        })
        .catch((e) => {
          console.log("====================================");
          console.log(e);
          console.log("====================================");
        });
    }
  };

  const handleClear = () => {
    setFromDate("");
    setToDate("");
    setErrors({});
    setPage(1);
    pageChange(null, 0);
    setPrevCount(1);
    setNextCount(10);
    const { apiUrl } = location.state;
    getApi(apiUrl)
      .then((res) => {
        setApiData(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log("====================================");
        console.log(e);
        console.log("====================================");
        setLoading(false);
      });
  };

  const handleDateChange =
    (setDate: React.Dispatch<React.SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setDate(event.target.value);
      // Reset year selection when dates are changed manually
      const yearSelect = document.querySelector("select") as HTMLSelectElement;
      if (yearSelect) {
        yearSelect.value = "0";
      }
    };

  const handleBookMarkPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setBookMarkPage(value);
  };

  const displayBookmarks = (bookMarks: SubBookMark[]) => {
    const startIndex = (bookMarkPage - 1) * bookMarksPerPage;
    const endIndex = startIndex + bookMarksPerPage;
    return bookMarks.slice(startIndex, endIndex);
  };

  const handleDeleteBookMark = (id: number) => {
    setLoading(true);
    const data: string | null = window.localStorage.getItem("loginsession");
    if (data) {
      const user: User = JSON.parse(data);

      remove(`${apiConstant.deleteBookmarked}/${user.userId}`, `${id}`)
        .then((res) => {
          toast.success("Book Mark deleted successfully.");
          setSelectedBookMark((prevBookMark) => {
            if (!prevBookMark) return prevBookMark; // Return if prevBookMark is null or undefined

            // Filter out the bookmark with the given id
            const updatedSubBookMark = prevBookMark.subBookMark.filter(
              (item) => item.bookMarkID !== id
            );

            // Return the updated state object
            return {
              ...prevBookMark, // Spread the previous state
              subBookMark: updatedSubBookMark, // Update the subBookMark property
            };
          });
          // setSelectedBookMark(selectedBM);
          setLoading(false);
        })
        .catch((e) => {
          console.log("====================================");
          console.error(e);
          console.log("====================================");
        });
    } else {
      toast.error(commonStrings.SomethingWrong);
    }
  };
  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "0") {
      handleClear();
    } else {
      const year = Number(e.target.value);
      const startDate = new Date(year, 0, 1);
      const EndDate =
        year === new Date().getFullYear() ? new Date() : new Date(year, 11, 31);

      setFromDate(formatDate(startDate));
      setToDate(formatDate(EndDate));

      // Trigger filter API
      const { apiFilterUrl } = location.state;
      let url = apiFilterUrl;
      if (!url) return;
      url = url.replace("@From_Date", formatDate(startDate));
      url = url.replace("@To_Date", formatDate(EndDate));

      getApi(url)
        .then((res) => {
          setApiData(res);
          setPage(1);
          pageChange(null, 0);
        })
        .catch((e) => {
          console.log("====================================");
          console.log(e);
          console.log("====================================");
        });
    }
  };
  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRefineData(e.target.value);
    let newArray = apiData.filter((item) =>
      [item.header, item.headlines, item.infavour, item.dateOfDecision].some(
        (text) => text?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    if (newArray.length === 0) {
      newArray = [
        {
          header: "No Data Found",
          headlines: "",
          heading: "",
          infavour: "",
          dateOfDecision: null,
          caselawUrl: "",
          webpath: "",
          tiolCitation: "",
        },
      ];
    }
    setRefineItem(newArray);
  };

  const getBookMarks = () => {
    const data: string | null = window.localStorage.getItem("loginsession");
    if (data) {
      const user: User = JSON.parse(data);
      setCategory(homeSection.userMenuSettings[1].name);
      getApi(`${apiConstant.getAllBookmarked}/${user.userId}`)
        .then((res) => {
          setBookMarks(res);
          setSelectedBookMark(res[0]);
          setLoading(false);
        })
        .catch((e) => {
          console.log("====================================");
          console.log(e);
          console.log("====================================");
          setLoading(false);
        });
    } else {
      navigate("/sign-in");
    }
  };

  const generateYears = (): number[] => {
    const startYear: number = 2000;
    const endYear: number = new Date().getFullYear();
    const years: number[] = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    return years;
  };

  // Render shimmer loading state
  if (localLoading) {
    return (
      <div className="container mx-auto p-4">
        <div className="mb-6 animate-pulse">
          <div className="h-8 bg-gray-300 w-1/4 rounded mb-4"></div>
          <div className="h-4 bg-gray-300 w-full rounded"></div>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {[...Array(5)].map((_, index) => (
            <ShimmerEffect key={index} type="text" count={1} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      {location.pathname === homeSection.userMenuSettings[1].value ? (
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            {bookMarks.length > bookMarksPerPage && (
              <Pagination
                count={Math.ceil(bookMarks.length / bookMarksPerPage)}
                page={bookMarkPage}
                onChange={handleBookMarkPageChange}
              />
            )}
          </div>
          <div className="flex justify-center items-center">
            <label htmlFor="" className="w-56">
              Select Category :{" "}
            </label>
            <select
              name="author"
              id="author"
              value={selectedBookMark?.types}
              onChange={(e) => {
                const selected = bookMarks.find(
                  (item) => item.types === e.target.value
                );
                setSelectedBookMark(selected);
              }}
              className="mt-1 block  p-2 mx-5 w-full border border-gray-300 rounded-md shadow-sm"
            >
              <option value="0">Select</option>
              {bookMarks.map((item: BookMark, index) => (
                <option key={item.types} value={item.types}>
                  {item.types}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-center border rounded-2xl py-2 bg-gray-200 my-5">
            <div className="text-xl text-orangered uppercase">{category}</div>
          </div>
          {displayBookmarks(selectedBookMark?.subBookMark || []).length > 0 ? (
            displayBookmarks(selectedBookMark?.subBookMark || []).map(
              (item, index) => {
                const isExpanded = expandedItems[index];
                return (
                  <div
                    className="border border-gray-300 rounded-md p-2 mb-4"
                    key={index}
                  >
                    <div className="bg-gray-100 rounded-t-md p-2">
                      <div className="flex justify-between">
                        <div
                          className="text-orange-600 cursor-pointer"
                          onClick={() => {
                            let url = item.webpath;
                            let data = item.webpath.split("/");
                            let id = data[data.length - 1];
                            window.open(
                              `/news/details?id=${id}&view=${
                                selectedBookMark?.types.toLocaleLowerCase() ===
                                searchNames.Notification.toLowerCase()
                                  ? searchNames.Notification
                                  : category
                              }`,
                              "_blank"
                            );
                          }}
                        >
                          {item.dates}
                        </div>
                        <div className="px-5 ">
                          <MdOutlineDeleteOutline
                            size={20}
                            color="orangered"
                            className="cursor-pointer"
                            onClick={() => {
                              alert(
                                `Confirm to delete : ${item?.bookMarkID}-${
                                  item?.header.length > 0
                                    ? item?.header
                                    : item?.headlines
                                }`
                              );
                              handleDeleteBookMark(item.bookMarkID);
                            }}
                          />
                        </div>
                      </div>
                      <div className="font-bold text-gray-600 text-sm mt-2">
                        {item.author && (
                          <div className="text-gray-600 text-sm">
                            {item.author}
                          </div>
                        )}
                        {item.notification_Number && (
                          <div className="text-gray-600 text-sm">
                            {item.notification_Number}
                          </div>
                        )}
                        {item.header}
                      </div>
                      <div
                        className="text-gray-700 text-sm"
                        onClick={() => {
                          let url = item.webpath;
                          let data = item.webpath.split("/");
                          let id = data[data.length - 1];
                          window.open(
                            `/news/details?id=${id}&view=${
                              selectedBookMark?.types.toLocaleLowerCase() ===
                              searchNames.Notification.toLowerCase()
                                ? searchNames.Notification
                                : category
                            }`,
                            "_blank"
                          );
                        }}
                      >
                        {isExpanded
                          ? item.headlines
                          : item.headlines.slice(0, 310) +
                            (item.headlines.length > 310 ? "..." : "")}
                        {item.headlines.length > 310 && (
                          <button
                            onClick={() => toggleReadMore(index)}
                            className="text-orange-500 ml-2"
                          >
                            {isExpanded
                              ? commentPost.readLess
                              : commentPost.readMore}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="text-center text-orange-500 flex items-center justify-center text-3xl mt-10">
              {commonStrings.NoData}
            </div>
          )}
          {bookMarks.length > bookMarksPerPage && (
            <div className="flex justify-end mt-4">
              <Pagination
                count={Math.ceil(bookMarks.length / bookMarksPerPage)}
                page={bookMarkPage}
                onChange={handleBookMarkPageChange}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="p-4">
          {isSearch && (
            <div className="flex justify-between items-center mb-4">
              <div className="flex">
                <div>
                  {(pageOriData.length > 0 || !spinner) && (
                    <button
                      className="px-4 py-2 bg-orange-500 text-white rounded-md shadow-sm hover:bg-orange-600"
                      onClick={() => {
                        setSearchData && setSearchData([]);
                        setIsRefineFalse && setIsRefineFalse();
                      }}
                    >
                      {commonStrings.NEWSEARCH}
                    </button>
                  )}
                </div>
                {commonStrings.caselawquicksearch != searchName &&
                  !isRefineMode && (
                    <div>
                      <button
                        className="px-4 py-2 mx-2 bg-orange-500 text-white rounded-md shadow-sm hover:bg-orange-600"
                        onClick={() => {
                          refindSearch && refindSearch(true);
                          setIsRefineMode(true);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        {commonStrings.REFINESEARCH}
                      </button>
                    </div>
                  )}
              </div>
              <div>
                <input
                  type="text"
                  value={refineData}
                  placeholder="Find"
                  className="border rounded p-2 ml-4"
                  onChange={handleClick}
                  disabled={isRefineMode}
                />
              </div>
            </div>
          )}
          {!isSearch &&
            !todaysCase &&
            !location.pathname.includes("/gst/sgst") && (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="xl:grid xl:grid-cols-12  space-y-3 gap-4 items-center mb-4"
                >
                  <div className="xl:col-span-2 border rounded-md shadow-sm col-span-12 mt-3">
                    <select
                      onChange={handleYearChange}
                      className="w-full py-[.9rem] px-1 border border-gray-300 rounded-md uppercase "
                    >
                      <option value="0">YEAR</option>
                      {years
                        .sort((a, b) => b - a)
                        .map((item: number) => (
                          <option
                            key={item}
                            value={item}
                            selected={item === new Date().getFullYear()}
                          >
                            {item}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="xl:col-span-2 col-span-6">
                    <input
                      type="date"
                      id="fromDate"
                      value={fromDate}
                      max={getCurrentDate()}
                      onChange={handleDateChange(setFromDate)}
                      className={
                        errors.fromDate
                          ? "w-full py-3 px-1 border border-red-500 rounded-md shadow-sm uppercase "
                          : "w-full py-3 px-1 border border-gray-300 rounded-md shadow-sm uppercase "
                      }
                    />
                  </div>
                  <div className="xl:col-span-2 col-span-6 ">
                    <input
                      type="date"
                      id="toDate"
                      value={toDate}
                      // min={fromDate}
                      max={getCurrentDate()}
                      onChange={handleDateChange(setToDate)}
                      className={
                        errors.toDate
                          ? "w-full py-3 px-1  border border-red-500 rounded-md shadow-sm uppercase "
                          : "w-full py-3 px-1  border border-gray-300 rounded-md shadow-sm uppercase"
                      }
                    />
                  </div>

                  <div className="xl:col-span-3 col-span-12 flex items-start  space-x-2 justify-start ">
                    <button
                      className="px-4 py-2 bg-orange-500 text-white rounded-md shadow-sm hover:bg-orange-600"
                      type="submit"
                    >
                      {commonStrings.FILTER}
                    </button>
                    <button
                      className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md shadow-sm hover:bg-gray-400"
                      type="reset"
                      onClick={handleClear}
                    >
                      {commonStrings.CLEAR}
                    </button>
                  </div>

                  <div className="xl:col-span-3 col-span-12 ">
                    <input
                      type="text"
                      value={refineData}
                      placeholder="Find"
                      className="border rounded p-2 w-full"
                      onChange={handleClick}
                    />
                  </div>
                </form>
              </>
            )}
          {location.pathname.includes("/gst/sgst") && (
            <div className="flex justify-between mb-4 gap-4">
              <select
                onChange={(e) => {
                  setState(e.target.value);
                  setUt("");
                }}
                value={state}
                className="w-full p-3  border-gray-300  border rounded-md uppercase "
              >
                <option value="0">Select State</option>
                {states.map((item: { state: string }, index: number) => (
                  <option key={index} value={item.state}>
                    {item.state}
                  </option>
                ))}
              </select>
              <div></div>
              <select
                onChange={(e) => {
                  setUt(e.target.value);
                  setState("");
                }}
                value={ut}
                className="w-full p-3  border-gray-300  border rounded-md uppercase "
              >
                <option value="0">Select Ut</option>
                {uts.map((item: { ut: string }, index: number) => (
                  <option key={index} value={item.ut}>
                    {item.ut}
                  </option>
                ))}
              </select>
            </div>
          )}
          {caseLawdata.length > 0 && pageOriData.length > intialCount && (
            <div className="md:flex justify-between items-center mb-4 hidden px-4">
              <div className="flex items-center">
                <span className="text-red-600 text-sm">{prevCount}</span>
                <span className="mx-1">-</span>
                <span className="text-red-600 text-sm">{nextCount}</span>
                <span className="mx-1">out of</span>
                <span className="text-red-600 text-sm">
                  {pageOriData.length}
                </span>
              </div>
              <div className="p-2">
                <Pagination
                  count={Math.ceil(pageOriData.length / 10)}
                  page={page}
                  onChange={pageChange}
                />
              </div>
            </div>
          )}
          {!isSearch && (
            <>
              <div className="flex items-center justify-center border rounded-2xl py-2 bg-gray-200 mb-5">
                <div className="md:text-xl text-orangered text-center uppercase">
                  {category}
                </div>
              </div>
            </>
          )}
          {isSearch && (
            <>
              <div className="flex items-center justify-center border rounded-2xl py-2 bg-gray-200 mb-5">
                <div className="md:text-xl text-orangered text-center capitalize">
                  {searchName}
                </div>
              </div>
              <div className="border border-orangered rounded-md p-4 mb-3">
                {searchFieldData && Object.keys(searchFieldData).length > 0 ? (
                  Object.keys(searchFieldData).map((key) => {
                    const value = searchFieldData[key];
                    if (value && value !== "0") {
                      return (
                        <div key={key} className="mb-2 flex gap-4">
                          <div className="text-orangered font-bold uppercase">
                            {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </div>
                          <div>:</div>
                          <div>{value}</div>
                        </div>
                      );
                    }
                    return null; // Return null if the value is falsy
                  })
                ) : (
                  <div className="text-gray-500">{commonStrings.NoData}</div>
                )}
              </div>
            </>
          )}
          <div>
            {caseLawdata.length > 0
              ? caseLawdata.map((item, index) => {
                  if (item.header === "No Data Found") {
                    return (
                      <div className="text-center text-orange-500 flex items-center justify-center text-3xl mt-10">
                        {commonStrings.NoData}
                      </div>
                    );
                  }
                  const isExpanded = expandedItems[index];
                  return (
                    <div
                      className="border border-gray-300 rounded-md p-2 mb-4 hover:text-orangered"
                      key={index}
                      onClick={() => rowDataClickandler(item)}
                    >
                      <div
                        className=" rounded-t-md p-2 "
                        onClick={() => rowDataClickandler(item)}
                      >
                        <div
                          className=" cursor-pointer"
                          onClick={() => rowDataClickandler(item)}
                        >
                          {!item.tiolCitation && !item.date && (
                            <>
                              {commonStrings.Infavour} : {item.infavour} |{" "}
                              {commonStrings.DOD} :
                            </>
                          )}{" "}
                          {item.dateOfDecision ||
                            item.date ||
                            item.tiolCitation}
                        </div>
                        <div className="font-bold text-sm my-2 cursor-pointer">
                          {item.author && (
                            <div className=" text-sm">{item.author}</div>
                          )}
                          {item.notification_Number && (
                            <div className=" text-sm">
                              {item.notification_Number}
                            </div>
                          )}
                          {item.header}
                        </div>
                        {item.headlines && (
                          <div className=" text-sm cursor-pointer">
                            {isExpanded
                              ? item.headlines
                              : item.headlines?.slice(0, 310) +
                                (item.headlines?.length > 310 ? "..." : "")}
                            {item.headlines?.length > 310 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleReadMore(index);
                                }}
                                className="text-blue-500 ml-2"
                              >
                                {isExpanded ? "Read Less" : "Read More"}
                              </button>
                            )}
                          </div>
                        )}
                        {item.heading && (
                          <div className=" text-sm cursor-pointer">
                            {isExpanded
                              ? item.heading.replace("/t", "")
                              : item.heading?.slice(0, 310) +
                                (item.heading?.length > 310 ? "..." : "")}
                            {item.heading?.length > 310 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleReadMore(index);
                                }}
                                className="text-blue-500 ml-2"
                              >
                                {isExpanded ? "Read Less" : "Read More"}
                              </button>
                            )}
                            <br />
                            {item.file_Path_Hindi && (
                              <>
                                <div>
                                  <strong>{commonStrings.hindi} : </strong>{" "}
                                  <Link
                                    to={item.file_Path_Hindi}
                                    target="_blank"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(
                                        item.file_Path_Hindi,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    {item.title_Hindi_Pdf ||
                                      item.file_Path_Hindi}
                                  </Link>{" "}
                                </div>
                              </>
                            )}

                            {item.file_Path_English && (
                              <>
                                <div>
                                  <strong>{commonStrings.english} : </strong>
                                  <Link
                                    to={item.file_Path_English}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(
                                        item.file_Path_English,
                                        "_blank"
                                      );
                                    }}
                                  >
                                    {item.title_English_Pdf ||
                                      item.file_Path_English}
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              : spinner && (
                  <div className="text-center text-orange-500 flex items-center justify-center text-3xl mt-10">
                    {commonStrings.NoData}
                  </div>
                )}
          </div>
          {caseLawdata.length > 0 && pageOriData.length > intialCount && (
            <div className="flex justify-end mt-4">
              <Pagination
                count={Math.ceil(pageOriData.length / 10)}
                page={page}
                onChange={pageChange}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SearchData;
