import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "./styles/captchaverification.css";

import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { isSafari } from "./utils/dateFunctions";
import BackToTop from "./components/backToTop/BackToTop";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div className={isSafari() ? "safari" : ""}>
    <ToastContainer />
    <Provider store={store}>
    <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>   
        </React.StrictMode>  
    </Provider>
    <BackToTop />
  </div>
);
