import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import { commonStrings } from '../../services/stringConstant';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TiPlus, TiThMenu } from "react-icons/ti";
import { MdNavigateBefore, MdNavigateNext, MdPlayArrow, MdPause } from 'react-icons/md';
import tiol_logo from "../../assets/images/tiol_logo.png";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FaMinus } from 'react-icons/fa';
import ShimmerEffect from '../ShimmerEffect';
import { getApi } from '../../services/apiService';

interface DailyNewsProps {
    data: any;
}
interface ApiData {
    heading: string; link: string
}

const DailyNews: FC<DailyNewsProps> = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [apiData, setApiData] = useState<ApiData[]>([]);
    const openModal = () => {
        setIsModalOpen(true)
    };
    const closeModal = () => setIsModalOpen(false);

    const swiperRef = useRef<any | null>(null);
    const [isAutoplaying, setIsAutoplaying] = useState<boolean>(true);

    const handleStop = () => {
        if (swiperRef.current?.autoplay) {
            swiperRef.current.autoplay.stop();
            setIsAutoplaying(false);
        }
    };

    const handlePlay = () => {
        if (swiperRef.current?.autoplay) {
            swiperRef.current.autoplay.start();
            setIsAutoplaying(true);
        }
    };
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    // Deduplicate section data by ID to prevent duplicates in Firefox
     useEffect(() => {
        try{
            if (!data?.apipath) {
                return;
            }
            getApi(data?.apipath).then((res) => {
                console.log('====================================');
                console.log(data?.apipath);
                console.log('====================================');
                
                setApiData(res);
            }).catch(e => {
                console.log('====================================');
                console.log(e);
                console.log('====================================');
            });
        }catch(e){
            console.log('====================================');
            console.log(e);
            console.log('====================================');
        }
    }, [data]);
 
    

    // Check if data is available
    if (!apiData) {
        return (
            <div className='bg-[#dbdde1] py-2 container mx-auto px-3'>
                <div className='container flex justify-between mx-auto'>
                    <strong className="text-orangered">
                        News Update
                    </strong>
                </div>
                <div className="py-4">
                    <ShimmerEffect type="text" count={1} />
                </div>
            </div>
        );
    }
    
    return (
        <>
            <div className='bg-[#dbdde1] py-2  container mx-auto  px-3  items-center text-orangered '>
                <div className='container flex justify-between mx-auto'>
                    <strong>
                        News Update
                    </strong>
                    <div className='flex items-center space-x-2 cursor-pointer '>
                        <TiThMenu onClick={openModal} />
                        <MdNavigateBefore size={20} className="custom-prev" />
                        {isAutoplaying ? (
                            <MdPause onClick={handleStop} className="play-stop-btn" />
                        ) : (
                            <MdPlayArrow onClick={handlePlay} className="play-stop-btn" />
                        )}
                        <MdNavigateNext size={20} className="custom-next" />

                        {/* {!isCollapsed ? <FaMinus onClick={toggleCollapse} />
                            : <TiPlus onClick={toggleCollapse} />} */}
                    </div>

                </div>
            </div>

            {!isCollapsed && (
                <div className="bg-[#dbdde1]  py-2 container mx-auto rounded-b-md px-3 transition-all duration-500 ease-in-out" >
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: '.custom-next',
                            prevEl: '.custom-prev',
                        }}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                        onSwiper={(swiperInstance: any) => {
                            swiperRef.current = swiperInstance;
                        }}
                    >
                        {apiData.map((item: ApiData, index : number) => (
                            <SwiperSlide key={`swiper-${index}`}>
                                {item.link ? (
                                    <a className='mr-10 flex items-center cursor-pointer hover:underline' href={item.link} target="_blank" rel="noopener noreferrer">{item.heading}</a>
                                ) : (
                                    <span className='mr-10 flex items-center'>{item.heading}</span>
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>)}

            {isModalOpen && <div
                id="static-modal"
                className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
            >
                <div className="relative p-4 w-full max-w-5xl max-h-full">
                    <div className="relative bg-white rounded-md shadow ">
                        <div className="flex items-center justify-around gap-4 p-4 md:p-5 rounded-t border-b border-orange-200">
                            {/* <div className='flex items-center'>
                                <img
                                    src={tiol_logo}
                                    alt="logo"
                                    className="max-w-14"
                                />
                            </div> */}
                            <div className='flex items-center justify-center'>
                                <h3 className="text-xl font-semibold text-orange-main ">{commonStrings.NEWS_UPDATE}</h3>
                            </div>

                            <button
                                type="button"
                                className="text-orange-main bg-transparent  rounded-md text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  "
                                onClick={closeModal}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-7 space-y-4 text-left overflow-y-auto max-h-[80vh]">
                            {apiData.map((item: ApiData , index: number) => (
                                <div key={`modal-${index}`}>
                                {item.link ? (
                                    <a className='flex items-center hover:text-orange-500 hover:underline' href={item.link} target="_blank" rel="noopener noreferrer">
                                        {item.heading && <div className='font-bold text-sm my-2 cursor-pointer'>
                                            {item.heading}
                                        </div>}
                                    </a>
                                ) : (
                                    <div className='flex items-center'>
                                        {item.heading && <div className='font-bold text-sm my-2'>
                                            {item.heading}
                                        </div>}
                                    </div>
                                )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
};

export default DailyNews;